<template>
  <div class="answer">
    <el-card>
      <el-form :model="queryInfo">
        <el-form-item label="最新消息时间：">
          <el-radio-group v-model="queryInfo.resource" @change="changeTime">
            <el-radio label="0">不限</el-radio>
            <el-radio label="1">近三天</el-radio>
            <el-radio label="2">近一周</el-radio>
            <el-radio label="3">近一个月</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Answer",
  data() {
    return {
      queryInfo: {
        resource: "0",
      },
    };
  },
  methods: {
    changeTime(val) {},
  },
};
</script>

<style lang="less" scoped>
.answer {
  width: 100%;

  /deep/ .el-form-item{
    margin-bottom: 0;
  }

  /deep/ .el-form-item__label{
    color: #252631;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>